import React from "react";

class Page extends React.Component {
render() {
return (
<div>
  <div className="container">
    <header class="header">
      <div class="l-center header-top">
        <hgroup class="brand">
          <h1 class="logo"><a href="/">Bronchosedal</a></h1>
        </hgroup>
      </div>
    </header>
    <div className="main">
      <div className="l-center">
        <div className="row">
          <div className="col col_2-3 user-content	">
            <div className="row">
              <h3>Onderhoudswerkzaamheden</h3>
              <section className="intro">
                <p>De website wordt momenteel herzien. Bedankt voor uw begrip.</p>
               
              </section>
            </div>
            
          </div>
         
        </div>
       
      </div>
    </div>
  </div>
  
  
      </div>
      );
    }
  }

  export default Page;